import React, { useState, useRef, useEffect } from "react";
import DownloadIcon from "../../assets/Images/downloadIcon.png";
import { getDigitalProjectById } from "../../API/apiService";
import { useParams } from "react-router-dom";
import ForgeViewer from "../AutoDeskForge/ForgeViewer";
import ForgeViewer2d from "../AutoDeskForge/ForgeViewer2d";
import ConvertIcon from "../../assets/Icons/convert.png";
import ShareIcon from "../../assets/Icons/share.png";
import ShareDialog from "../Resource/ShareButton";
import categoryForgeActive from "../../Models/categoryForgeActive";
import ArrowDown from "../../assets/Icons/arrowDown.png";
import ArrowUp from "../../assets/Icons/up-arrow.png";
import { useNavigate } from "react-router-dom";
import backIcon from "../../assets/Icons/left-chevron.png";
import { useTranslation } from "react-i18next";
import {
  getTotalProperty,
  getPropertyValue,
} from "../../Helpers/forgeViewerUtils";
import { GlobalContext } from "../../GlobalContext/GlobalContext";
import { useContext } from "react";

export default function OrderDetailsDigital() {
  const { selectedLevel, viewerLoading } = useContext(GlobalContext);
  const containerRef = useRef(null);
  const [project, setProject] = useState();
  const forgeViewerRef = useRef(null);
  const { t } = useTranslation();
  const fetcherFunctionRef = useRef(null);
  const [data, setData] = useState([]);
  const [activeElement, setActiveElement] = useState("");
  const [isViewer3dActive, setViewer3dActive] = useState(true);
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const [categoryTabActive, setCategoryTabActive] =
    useState(categoryForgeActive);
  const [openCard, setOpenCard] = useState(true);
  const [loading, setLoading] = useState(false);
  let { orderId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getDigitalProjectById(orderId);
        setProject(response.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const selectByLevel = async () => {
      if (forgeViewerRef.current) {
        if (data.length > 0) {
          if (
            fetcherFunctionRef &&
            fetcherFunctionRef.current &&
            typeof fetcherFunctionRef.current === "function"
          ) {
            await handleGetData(activeElement, fetcherFunctionRef.current);
          } else {
        //    console.error("fetcherFunctionRef.current is not a valid function");
          }
        } else {
          await forgeViewerRef.current.isolateElementsByLevel(selectedLevel);
        }
      }
    };

    selectByLevel();
  }, [selectedLevel]);

  const getTotalDaysBeforeLinkExpires = () => {
    if (project) {
      const linkAddedAt = new Date(project.workLinkCreatedAt); // When the link was added
      const expirationDate = new Date(
        linkAddedAt.getTime() + 30 * 24 * 60 * 60 * 1000
      ); // 30 days later
      const currentDateTime = new Date(); // Current date-time

      // Calculate the difference in milliseconds
      const difference = expirationDate - currentDateTime;

      // Convert milliseconds to days
      const differenceInDays = Math.ceil(difference / (1000 * 60 * 60 * 24));

      return differenceInDays > 0 ? differenceInDays : 0; // Return 0 if the link has already expired
    }
  };

  const daysLeft = getTotalDaysBeforeLinkExpires();

  const lengthElements = [
    "walls",
    "pipes",
    "conduits",
    "railings",
    "mullions",
    "structuralFraming",
    "cableTrays",
    "ducts",
    "planting",
    "site",
  ];
  const perimeterElements = ["ceilings", "floors"];

  const areaElements = [
    "walls",
    "ceilings",
    "floors",
    "curtainPanels",
    "windows",
    "topography",
    "roofs",
  ];
  const volumeElements = ["walls"];
  const heightElements = ["columns"];
  const countElements = [
    "windows",
    "doors",
    "lightingFixtures",
    "ramps",
    "stairs",
    "casework",
    "furniture",
    "plumbingFixtures",
    "specialityEquipment",
    "lightingDevices",
    "airTerminals",
    "sprinklers",
    "pipeFittings",
    "ductFittings",
    "conduitFittings",
    "parkingsSite",
    "mechanicalEquipment",
    "genericModels",
    "columns",
    "curtainPanels",
    "entourage",
    "structuralFraming",
    "plantings",
    "mullions",
    "electricalEquipment",
    "electricalFixtures",
  ];

  const handleSetForgeCategoryActive = async (e, category) => {
    e.preventDefault();

    setCategoryTabActive((prevState) => ({
      [category]: !prevState[category], // Toggle the specific category
    }));
    if (category === "architecture") {
      const action = categoryTabActive.architecture ? "remove" : "add";
      //in the same method remove or add architecture, pas it as param , remove or add
      await forgeViewerRef.current.getArchitectureCategory(action);

      //  await forgeViewerRef.current.getArchitectureCategory();
    } else if (category === "furniture") {
      const action = categoryTabActive.furniture ? "remove" : "add";
      await forgeViewerRef.current.getFurnitureCategory(action);
    } else if (category === "mepf") {
      const action = categoryTabActive.mepf ? "remove" : "add";
      await forgeViewerRef.current.getMEPFCategory(action);
    } else if (category === "site") {
      const action = categoryTabActive.site ? "remove" : "add";
      await forgeViewerRef.current.getSiteCategory(action);
    }
  };

  const handleGetData = async (componentName, fetcherFunction) => {
    try {
      fetcherFunctionRef.current = fetcherFunction;

      let data = await fetcherFunction();
      
      if (selectedLevel && selectedLevel.toLowerCase() !== "all levels") {
        data = await forgeViewerRef.current.getElementsByLevel(
          data,
          selectedLevel
        );
        if (data[0]?.elements.length === 0) {
          data = [];
        }
      }
      setData(data);
      setActiveElement(componentName);

      return data;
    } catch (error) {
      throw error;
    }
  };

  const getSelectedProperties = async (typeName) => {
    const selectedGroup = data?.find((prop) => prop.typeName === typeName);
    if (selectedGroup) {
      const dbIds = [];
      selectedGroup.elements.forEach((element) => {
        dbIds.push(element.dbId);
      });
      if (dbIds) {
        await forgeViewerRef.current.isolateGroup(dbIds);
      }
    }
    return selectedGroup;
  };

  function getTotalElements(array) {
    let totalElements = 0;

    array?.forEach((item) => {
      if (item.elements && Array.isArray(item.elements)) {
        totalElements += item.elements.length;
      }
    });

    return totalElements;
  }

  const propertyTypeExist = (propType) => {
    const spaces = project?.propertyInfoDigital?.propertyType;
    return spaces ? spaces.some((prop) => prop.type === propType) : false;
  };

  const interiorScopeExist = (interiorScope) => {
    const intScope = project?.propertyInfoDigital?.interiorScope;
    return intScope
      ? intScope.some((interior) => interior.type === interiorScope)
      : false;
  };

  const exteriorScopeExist = (exteriorScope) => {
    const extScope = project?.propertyInfoDigital?.exteriorScope;
    return extScope
      ? extScope.some((exterior) => exterior.type === exteriorScope)
      : false;
  };
  const convertToImperial = (unit) => {
    const unitMap = {
      "m": "ft",
      "m²": "ft²",
      "m³": "ft³",
    };
    return unitMap[unit] || unit;
  };
  //const shareUrl = `${import.meta.env.VITE_APP_REACT_BASE_URL}/orders/digital/${orderId}`;
  const shareTitle = "Check out this property digitalization!";
  const propertyConfig = {
    length: {
      elements: lengthElements,
      unit: "m",
    },
    height: {
      elements: heightElements,
      unit: "m",
    },
    perimeter: {
      elements: perimeterElements,
      unit: "m",
    },
    area: {
      elements: areaElements,
      unit: "m²",
    },
    volume: {
      elements: volumeElements,
      unit: "m³",
    },

    count: {
      elements: countElements,
      unit: "",
    },
  };

  if (loading || viewerLoading) {
    return (
      <div className="top-od-cont">
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  const getActiveProperties = (activeElement) => {
    return Object.entries(propertyConfig).reduce((acc, [key, config]) => {
      if (config.elements.includes(activeElement)) {
        acc.push({ type: key, unit: config.unit });
      }
      return acc;
    }, []);
  };

  const renderProperties = (activeElement) => {
    const properties = getActiveProperties(activeElement);
    if (!properties.length) return null;

    return (
      <>
        <div className="property-total">
          <p>{t("elements.total")}</p>
          {properties.map(({ type, unit }) => (
            <p key={type}>
              {type === "count"
                ? getTotalElements(data || [])
                : getTotalProperty(data || [], type, activeElement)}
              {project.propertyInfoDigital.unit === "metric"
                ? ` ${unit}`
                : ` ${convertToImperial(unit)}`}
            </p>
          ))}
        </div>
        {data?.map((property) => (
          <div
            key={property.typeName}
            className="property-type-info"
            onClick={() => getSelectedProperties(property.typeName)}
          >
            <p className="property-digital">{property.typeName}</p>
            {properties.map(({ type, unit }) => (
              <p key={type} className="property-digital property-data">
                {type === "count"
                  ? property.elements?.length
                  : getPropertyValue(property || [], type, activeElement)}
                {project.propertyInfoDigital.unit === "metric"
                  ? ` ${unit}`
                  : ` ${convertToImperial(unit)}`}
              </p>
            ))}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="top-od-cont">
      <>
        {project && (
          <>
            <div className="top-title">
              <div>
                <img
                  onClick={() => navigate("/orders/digital")}
                  style={{ width: "30px", height: "30px", cursor: "pointer" }}
                  aria-label="back"
                  src={backIcon}
                  alt="back-icon"
                />
              </div>
              <div>
                <p style={{ textAlign: "center" }} className="gradient-text">
                  <span>{t("orderDetailsDigital.orderDetailsTitle")}</span>
                </p>
              </div>
            </div>

            <div></div>
            <div className="order-details-container">
              {project?.status === "Completed" && (
                <>
                  <div
                    className={`content3d-container${
                      isViewer3dActive ? "-3d-Active" : "-3dInactive"
                    }`}
                    style={{
                      width: isViewer3dActive ? "600px" : "800px",
                      height: "600px",
                    }}
                  >
                    {/* Card content */}
                    <div
                      className={`${
                        isViewer3dActive ? "digital-icons" : "digital-icons-2d"
                      }`}
                    >
                      <img
                        onClick={() => setViewer3dActive(!isViewer3dActive)}
                        className="convert-icon"
                        src={ConvertIcon}
                        alt="content-3d"
                      />
                      {isViewer3dActive && (
                        <>
                          <div className="digital-category-tab">
                            <p
                              className={`${
                                categoryTabActive.architecture === true
                                  ? "active-button"
                                  : "inactive-button"
                              }`}
                              onClick={(e) =>
                                handleSetForgeCategoryActive(e, "architecture")
                              }
                            >
                              {t("orderDetailsDigital.architecture")}
                            </p>
                            <p
                              className={`${
                                categoryTabActive.furniture === true
                                  ? "active-button"
                                  : "inactive-button"
                              }`}
                              onClick={(e) =>
                                handleSetForgeCategoryActive(e, "furniture")
                              }
                            >
                              {t("orderDetailsDigital.furniture")}
                            </p>
                            <p
                              className={`${
                                categoryTabActive.mepf === true
                                  ? "active-button"
                                  : "inactive-button"
                              }`}
                              onClick={(e) =>
                                handleSetForgeCategoryActive(e, "mepf")
                              }
                            >
                              {t("orderDetailsDigital.mepf")}
                            </p>
                            <p
                              className={`${
                                categoryTabActive.site === true
                                  ? "active-button"
                                  : "inactive-button"
                              }`}
                              onClick={(e) =>
                                handleSetForgeCategoryActive(e, "site")
                              }
                            >
                              {t("orderDetailsDigital.site")}
                            </p>
                          </div>
                          <div className="digital-svg">
                            <img
                              className={`${
                                categoryTabActive.architecture === true
                                  ? "active-button"
                                  : "inactive-button"
                              }`}
                              onClick={(e) =>
                                handleSetForgeCategoryActive(e, "architecture")
                              }
                              src="/arch-scope-icon.svg"
                              alt="Arch Icon"
                              width="24"
                              height="24"
                            />
                            <img
                              className={`${
                                categoryTabActive.furniture === true
                                  ? "active-button"
                                  : "inactive-button"
                              }`}
                              onClick={(e) =>
                                handleSetForgeCategoryActive(e, "furniture")
                              }
                              src="/furn-scope-icon.svg"
                              alt="Furn Icon"
                              width="24"
                              height="24"
                            />
                            <img
                              className={`${
                                categoryTabActive.mepf === true
                                  ? "active-button"
                                  : "inactive-button"
                              }`}
                              onClick={(e) =>
                                handleSetForgeCategoryActive(e, "mepf")
                              }
                              src="/mep-scope-icon.svg"
                              alt="Mep Icon"
                              width="24"
                              height="24"
                            />
                            <img
                              className={`${
                                categoryTabActive.site === true
                                  ? "active-button"
                                  : "inactive-button"
                              }`}
                              onClick={(e) =>
                                handleSetForgeCategoryActive(e, "site")
                              }
                              src="/site.svg"
                              alt="Site Icon"
                              width="24"
                              height="24"
                            />
                          </div>
                        </>
                      )}
                      <img
                        onClick={() => setShareDialogOpen(!isShareDialogOpen)}
                        className="share-icon"
                        src={ShareIcon}
                        alt="share-icon"
                      />
                    </div>

                    <div
                      className={`${
                        isViewer3dActive
                          ? "forge-container"
                          : "forge-container-2d"
                      }`}
                    >
                      {isViewer3dActive ? (
                        <ForgeViewer
                          urn={project.urn}
                          hideFloorSelector={false}
                          ref={forgeViewerRef}
                        />
                      ) : (
                        <ForgeViewer2d urn={project.urn} />
                      )}
                    </div>
                  </div>
                  <ShareDialog
                    open={isShareDialogOpen}
                    onClose={() => setShareDialogOpen(false)}
                    url={project?.shareLink}
                    title={shareTitle}
                  />
                </>
              )}
              {(project?.status === "InProgress" ||
                project?.status === "Refreshed" ||
                project?.status === "Canceled") && (
                <>
                  <div className="message-container">
                    <div className="message-header">
                      {project?.status !== "Canceled" &&
                        t("orderDetailsScan.orderInProgress")}
                    </div>
                    <div className="message-body">
                      {project?.status === "Canceled"
                        ? t("orderDetailsScan.orderCanceledMessage")
                        : t("orderDetailsScan.orderProcessingMessage")}
                    </div>
                    <div className="message-footer">
                      {project?.status !== "Canceled" &&
                        t("orderDetailsScan.appreciationMessage")}
                    </div>
                  </div>
                </>
              )}
              {project?.status === "Draft" && (
                <>
                  <div className="message-container">
                    <div className="message-header">
                      {project?.status !== "Canceled" &&
                        t("orderDetailsScan.thankYouMessage")}
                    </div>
                    <div className="message-body">
                      {t("orderDetailsScan.awaitingPayment")}
                    </div>
                  </div>
                </>
              )}
              {isViewer3dActive && (
                <div
                  className={`order-details-content ${
                    project.urn && project?.status === "Completed"
                      ? "digital-viewer-active"
                      : ""
                  }`}
                >
                  <div className="arrow-down-cont">
                    {project.status === "Completed" && (
                      <img
                        onClick={() => {
                          setOpenCard(!openCard);
                        }}
                        className="arrow-digital"
                        src={openCard ? ArrowDown : ArrowUp}
                        alt="arrow-down"
                      />
                    )}
                  </div>
                  {openCard && (
                    <>
                      <p className="proj-name-st">{project.projectName}</p>
                      <p className="proj-address-st">
                        {project.propertyInfoDigital?.address}
                      </p>
                      <p className="proj-title-st">
                        {" "}
                        {t("orderDetailsDigital.digitalizationDetailsTitle")}
                      </p>
                      <div className="od-types-cont">
                        <div className="od-space-cont">
                          <div>
                            <p> {t("orderDetailsDigital.spaceType")}</p>
                          </div>

                          <div className="space-type-cont">
                            <p
                              className={`space-type${
                                propertyTypeExist("Standard")
                                  ? "-exist"
                                  : "-notExist"
                              }`}
                            >
                              {t("orderDetailsDigital.spaceTypeStandard")}
                            </p>
                            <p
                              className={`space-type${
                                propertyTypeExist("Industrial")
                                  ? "-exist"
                                  : "-notExist"
                              }`}
                            >
                              {t("orderDetailsDigital.spaceTypeIndustrial")}
                            </p>
                          </div>
                          <div>
                            <p> {t("orderDetailsDigital.spaceSiteSize")}</p>
                            <p>
                              {project.propertyInfoDigital.propertySize}/
                              {project.propertyInfoDigital.landSize}
                              <span>
                                {project.propertyInfoDigital.unit === "metric"
                                  ? " m²"
                                  : " SF"}
                              </span>
                            </p>
                          </div>
                          <div className="unit-cont2">
                            <p> {t("orderDetailsDigital.unit")}</p>
                            <div>
                              <p
                                className={`space-type${
                                  project.propertyInfoDigital?.unit ===
                                  "imperial"
                                    ? "-exist"
                                    : "-notExist"
                                }`}
                              >
                                {t("orderDetailsDigital.imperial")}
                              </p>
                              <p
                                className={`space-type${
                                  project.propertyInfoDigital?.unit === "metric"
                                    ? "-exist"
                                    : "-notExist"
                                }`}
                              >
                                {t("orderDetailsDigital.metric")}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="od-model-cont">
                          <div className="scope-title">
                            <p>
                              {" "}
                              {t("orderDetailsDigital.interiorScopeTitle")}
                            </p>
                          </div>

                          <div className="model-type model-type-1 cm-type">
                            {propertyTypeExist("Standard") ? (
                              <>
                                <p
                                  className={`space-type${
                                    interiorScopeExist("Architecture")
                                      ? "-exist"
                                      : "-notExist"
                                  }`}
                                >
                                  {t(
                                    "orderDetailsDigital.interiorScopeArchitecture"
                                  )}
                                </p>
                                <p
                                  className={`space-type${
                                    interiorScopeExist("Furniture")
                                      ? "-exist"
                                      : "-notExist"
                                  }`}
                                >
                                  {t(
                                    "orderDetailsDigital.interiorScopeFurniture"
                                  )}
                                </p>
                                <p
                                  className={`space-type${
                                    interiorScopeExist("Installations")
                                      ? "-exist"
                                      : "-notExist"
                                  }`}
                                >
                                  {t(
                                    "orderDetailsDigital.interiorScopeInstallations"
                                  )}
                                </p>
                              </>
                            ) : (
                              <p
                                className={`space-type${
                                  interiorScopeExist("COMPLEX Installations")
                                    ? "-exist"
                                    : "-notExist"
                                }`}
                              >
                                {t("orderDetailsDigital.complexInstallations")}
                              </p>
                            )}
                          </div>

                          {/* {project.propertyInfoDigital?.exteriorScope.length >
                          0 && (
                          <> */}
                          <div className="scope-title">
                            <p>{t("orderDetailsDigital.exteriorScopeTitle")}</p>
                          </div>
                          <div className="model-type">
                            <p
                              className={`space-type${
                                exteriorScopeExist("Architecture")
                                  ? "-exist"
                                  : "-notExist"
                              }`}
                            >
                              {t(
                                "orderDetailsDigital.interiorScopeArchitecture"
                              )}
                            </p>
                            <p
                              className={`space-type${
                                exteriorScopeExist("Furniture")
                                  ? "-exist"
                                  : "-notExist"
                              }`}
                            >
                              {t("orderDetailsDigital.interiorScopeFurniture")}
                            </p>
                            <p
                              className={`space-type${
                                exteriorScopeExist("Installations")
                                  ? "-exist"
                                  : "-notExist"
                              }`}
                            >
                              {t(
                                "orderDetailsDigital.interiorScopeInstallations"
                              )}
                            </p>
                          </div>
                          {/* </>
                        )} */}
                          <div
                          //   title={t("orderDetailsScan.downloadTooltip")}
                          >
                            <a
                              className="link"
                              href={project.workLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="btn-download-work-cont">
                                <img
                                  style={{
                                    width: "14px",
                                    maxWidth: "14px",
                                    marginRight: "4px",
                                  }}
                                  src={DownloadIcon}
                                  alt="download-icon"
                                />
                                <p>
                                  {" "}
                                  {t("orderDetailsDigital.downloadLinkText")}
                                </p>
                              </div>
                            </a>
                            {project.workLink && (
                              <>
                                {daysLeft === 0 ? (
                                  <p className="link-expires">
                                    {t("orderDetailsScan.downloadTooltip2")}
                                  </p>
                                ) : (
                                  <p className="link-expires">
                                    {t("orderDetailsScan.downloadTooltip", {
                                      daysLeft,
                                    })}
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {project.status === "Completed" && (
                    <>
                      <div className="package-container" ref={containerRef}>
                        <p
                          className={`element-button 
                            ${
                              activeElement === "walls"
                                ? "active-button"
                                : "inactive-button"
                            }`}
                          onClick={() =>
                            handleGetData(
                              "walls",
                              forgeViewerRef.current.getWalls
                            )
                          }
                        >
                          {t("elements.walls")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "ceilings"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "ceilings",
                              forgeViewerRef.current.getCeilings
                            )
                          }
                        >
                          {t("elements.ceilings")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "doors"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "doors",
                              forgeViewerRef.current.getDoors
                            )
                          }
                        >
                          {t("elements.doors")}
                        </p>
                        <p
                          className={`element-button
                            ${
                              activeElement === "windows"
                                ? "active-button"
                                : "inactive-button"
                            }`}
                          onClick={() =>
                            handleGetData(
                              "windows",
                              forgeViewerRef.current.getWindows
                            )
                          }
                        >
                          {t("elements.windows")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "floors"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "floors",
                              forgeViewerRef.current.getFloors
                            )
                          }
                        >
                          {t("elements.floors")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "stairs"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "stairs",
                              forgeViewerRef.current.getStairs
                            )
                          }
                        >
                          {t("elements.stairs")}
                        </p>
                        <p
                          className={`custom-paragraph element-button ${
                            activeElement === "columns"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "columns",
                              forgeViewerRef.current.getColumns
                            )
                          }
                        >
                          {t("elements.columns")}
                        </p>
                        <p
                          className={`custom-paragraph element-button ${
                            activeElement === "curtainPanels"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "curtainPanels",
                              forgeViewerRef.current.getCurtainPanels
                            )
                          }
                        >
                          {t("elements.curtainPanels")}
                        </p>
                        <p
                          className={`custom-paragraph element-button ${
                            activeElement === "mullions"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "mullions",
                              forgeViewerRef.current.getMullionsArch
                            )
                          }
                        >
                          {t("elements.mullions")}
                        </p>
                        <p
                          className={`custom-paragraph element-button ${
                            activeElement === "ramps"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "ramps",
                              forgeViewerRef.current.getRampsArch
                            )
                          }
                        >
                          {t("elements.ramps")}
                        </p>

                        <p
                          className={`custom-paragraph element-button ${
                            activeElement === "roofs"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "roofs",
                              forgeViewerRef.current.getRoofsArch
                            )
                          }
                        >
                          {t("elements.roofs")}
                        </p>

                        <p
                          className={`custom-paragraph element-button ${
                            activeElement === "airTerminals"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "airTerminals",
                              forgeViewerRef.current.getAirTerminalsInst
                            )
                          }
                        >
                          {t("elements.airTerminals")}
                        </p>

                        <p
                          className={`custom-paragraph element-button ${
                            activeElement === "cableTrays"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "cableTrays",
                              forgeViewerRef.current.getCableTraysInst
                            )
                          }
                        >
                          {t("elements.cableTrays")}
                        </p>

                        <p
                          className={`custom-paragraph element-button ${
                            activeElement === "electricalEquipment"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "electricalEquipment",
                              forgeViewerRef.current.getElectricalEquipmentInst
                            )
                          }
                        >
                          {t("elements.electricalEquipment")}
                        </p>

                        <p
                          className={`custom-paragraph element-button ${
                            activeElement === "electricalFixtures"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "electricalFixtures",
                              forgeViewerRef.current.getElectricalFixturesInst
                            )
                          }
                        >
                          {t("elements.electricalFixtures")}
                        </p>

                        <p
                          className={`custom-paragraph element-button ${
                            activeElement === "sprinklers"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "sprinklers",
                              forgeViewerRef.current.getSprinklersInst
                            )
                          }
                        >
                          {t("elements.sprinklers")}
                        </p>

                        <p
                          className={`custom-paragraph element-button ${
                            activeElement === "lightingDevices"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "lightingDevices",
                              forgeViewerRef.current.getLightingDevicesInst
                            )
                          }
                        >
                          {t("elements.lightingDevices")}
                        </p>

                        <p
                          className={`element-button ${
                            activeElement === "structuralFraming"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "structuralFraming",
                              forgeViewerRef.current.getStructuralFraming
                            )
                          }
                        >
                          {t("elements.structuralFraming")}
                        </p>
                        {/* <p
                            className={`element-button ${
                              activeElement === "structuralColumns"
                                ? "active-button"
                                : "inactive-button"
                            }`}
                            onClick={handleGetStructuralColumns}
                          >
                            {t("elements.structuralColumns")}
                          </p> */}

                        <p
                          className={`element-button ${
                            activeElement === "railings"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "railings",
                              forgeViewerRef.current.getRailings
                            )
                          }
                        >
                          {t("elements.railings")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "plumbingFixtures"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "plumbingFixtures",
                              forgeViewerRef.current.getPlumbingFixtures
                            )
                          }
                        >
                          {t("elements.plumbingFixtures")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "casework"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "casework",
                              forgeViewerRef.current.getCasework
                            )
                          }
                        >
                          {t("elements.casework")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "lightingFixtures"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "lightingFixtures",
                              forgeViewerRef.current.getLightingFixtures
                            )
                          }
                        >
                          {t("elements.lightingFixtures")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "furniture"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "furniture",
                              forgeViewerRef.current.getFurniture
                            )
                          }
                        >
                          {t("elements.furniture")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "genericModels"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "genericModels",
                              forgeViewerRef.current.getGenericModels
                            )
                          }
                        >
                          {t("elements.genericModels")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "specialityEquipment"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "specialityEquipment",
                              forgeViewerRef.current.getSpecialityEquipment
                            )
                          }
                        >
                          {t("elements.specialityEquipment")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "mechanicalEquipment"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "mechanicalEquipment",
                              forgeViewerRef.current.getMechanicalEquipment
                            )
                          }
                        >
                          {t("elements.mechanicalEquipment")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "pipes"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "pipes",
                              forgeViewerRef.current.getPipes
                            )
                          }
                        >
                          {t("elements.pipes")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "ducts"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "ducts",
                              forgeViewerRef.current.getDucts
                            )
                          }
                        >
                          {t("elements.ducts")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "conduits"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "conduits",
                              forgeViewerRef.current.getConduits
                            )
                          }
                        >
                          {t("elements.conduits")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "pipeFittings"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "pipeFittings",
                              forgeViewerRef.current.getPipeFittings
                            )
                          }
                        >
                          {t("elements.pipeFittings")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "ductFittings"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "ductFittings",
                              forgeViewerRef.current.getDuctFittings
                            )
                          }
                        >
                          {t("elements.ductFittings")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "conduitFittings"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "conduitFittings",
                              forgeViewerRef.current.getConduitFittings
                            )
                          }
                        >
                          {t("elements.conduitFittings")}
                        </p>

                        <p
                          className={`element-button ${
                            activeElement === "site"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "site",
                              forgeViewerRef.current.getSite
                            )
                          }
                        >
                          {t("elements.site")}
                        </p>
                        <p
                          className={`custom-paragraph element-button ${
                            activeElement === "parkingsSite"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "parkingsSite",
                              forgeViewerRef.current.getParkingsSite
                            )
                          }
                        >
                          {t("elements.parkings")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "plantings"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "plantings",
                              forgeViewerRef.current.getPlantings
                            )
                          }
                        >
                          {t("elements.plantings")}
                        </p>
                        <p
                          className={`element-button ${
                            activeElement === "topography"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "topography",
                              forgeViewerRef.current.getTopography
                            )
                          }
                        >
                          {t("elements.topography")}
                        </p>
                        <p
                          className={`custom-paragraph element-button ${
                            activeElement === "entourage"
                              ? "active-button"
                              : "inactive-button"
                          }`}
                          onClick={() =>
                            handleGetData(
                              "entourage",
                              forgeViewerRef.current.getEntourageSite
                            )
                          }
                        >
                          {t("elements.entourage")}
                        </p>
                      </div>
                      <div className="order-details-digital-tab">
                        <div className="digital-details-title">
                          {activeElement && (
                            <p className="pd-title-av"> {t("elements.type")}</p>
                          )}

                          {lengthElements.includes(activeElement) && (
                            <p className="pd-title-av">
                              {t("elements.length")}
                            </p>
                          )}
                          {heightElements.includes(activeElement) && (
                            <p className="pd-title-av">
                              {t("elements.height")}
                            </p>
                          )}
                          {perimeterElements.includes(activeElement) && (
                            <p className="pd-title-av">
                              {t("elements.perimeter")}
                            </p>
                          )}
                          {areaElements.includes(activeElement) && (
                            <p className="pd-title-av"> {t("elements.area")}</p>
                          )}
                          {volumeElements.includes(activeElement) && (
                            <p className="pd-title-av">
                              {t("elements.volume")}
                            </p>
                          )}
                          {countElements.includes(activeElement) && (
                            <p className="pd-title-av">{t("elements.count")}</p>
                          )}
                        </div>

                        {/* Show the elements data */}

                        <>{renderProperties(activeElement)}</>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </>
    </div>
  );
}
