import React, { useContext , useEffect} from 'react'
import AdminNavbar from './AdminNavbar'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styles from '../../Styles/adminPanel.module.css'
import Dashboard from './Dashborad/Dashboard'
import { GlobalContext } from '../../GlobalContext/GlobalContext'
import { getDigitalProjects, getScanProjects, fetchUserRole } from '../../API/apiService'
import { extractTypes, extractScanTypes, getProjectSatistics } from '../../Helpers/adjustProjectData'
import UsersGrid from '../Admin/Users/UsersGrid'
import Coupons from './Coupons/Coupons';
import toast from 'react-hot-toast';
import DisableDates from '../Calendar/DisableDates';
import AdminManagement from './Users/AdminManagement';
import { useNavigate } from 'react-router-dom';

export default function AdminPanel() {
  const {setDigitalProjects, setScanProjects,loading, setLoading, projectModified, activeService, setProjectSatistics} = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    if(loading) return;
    const toastId = toast.loading('...Loading data')
    const fetchProject = async () => {
      try {    
        setLoading(true);
        let response;
        let adjustedResponse = [];
        if(activeService === 'scan'){
          response = await getScanProjects();
          adjustedResponse = extractScanTypes(response.data);
          
          setScanProjects(adjustedResponse)
          setProjectSatistics(getProjectSatistics(adjustedResponse))
        }
        else if(activeService === 'digital'){
           response = await getDigitalProjects();
           adjustedResponse = extractTypes(response.data);
           
           setDigitalProjects(adjustedResponse);
           setProjectSatistics(getProjectSatistics(adjustedResponse))
        }              
        return response;
      } catch (error) {

      }finally{
        toast.dismiss(toastId)
        setLoading(false);
      }
    };
    fetchProject();
  },[projectModified, activeService])

  useEffect(() => {
    if(!(fetchUserRole().includes("Admin") || fetchUserRole().includes("Superadmin"))){
      navigate('/unauthorized')
    }
  },[])

  return (
    <div className={styles.container}>
        <div className={styles.adminNav}>
          <AdminNavbar/>
        </div>
        <div className={styles.mainContent}>
          <div>
            <Routes>
              <Route path='dashboard/:type' element={<Dashboard/>} />
              <Route path='users' element={<UsersGrid/>}/>
              <Route path='coupons' element={<Coupons/>}/>
              {/* <Route path='calendar' element={<DisableDates/>}/> */}
              <Route path='admin-management' element={<AdminManagement/>}/>
            </Routes>
          </div>
          
        </div>
    </div>
  )
}
