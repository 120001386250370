import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import calcImage from "../assets/Images/calcImage.png";
import InfoIcon from "../assets/Icons/InfoIcon.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  getTotalPriceDigital,
  getWorkingDaysRequired,
} from "../Helpers/helpers";
import {
  postDigitalOrder,
  getCurrentUser as getCurrentUser,
  checkCouponUsability,
  getCouponByName,
  getDigitalProjectByName,
} from "../API/apiService";
import toast from "react-hot-toast";
import { validateDigitalProject } from "../Validators/validator";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@mui/material";
import { IconButton, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import DiscountIcon from "@mui/icons-material/Discount";

const PricingCardDigital = ({ digitalModel, setDigitalModel }) => {
  const { t } = useTranslation();
  const [price, setPrice] = useState(0);
  const [workingDays, setTotalWorkingDays] = useState({
    interior: "",
    exterior: "",
    site: "",
  });
  const [coupon, setCoupon] = useState("");
  const [couponData, setCouponData] = useState({});
  const [couponInfo, setCouponInfo] = useState({
    isCouponApplied: false,
    originalPrice: 0,
    discountPrice: 0,
  });
  const [loading, setLoading] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false)
  const navigate = useNavigate();
  const spaceSize = digitalModel?.propertyInfoDigital?.propertySize;
  const unit = digitalModel?.propertyInfoDigital?.unit;
  const propertyType = digitalModel?.propertyInfoDigital?.propertyType;
  const interiorScope = digitalModel?.propertyInfoDigital?.interiorScope;
  const exteriorScope = digitalModel?.propertyInfoDigital?.exteriorScope;
  const siteSize = digitalModel?.propertyInfoDigital?.landSize;
  const drawingsPdf = digitalModel?.propertyInfoDigital?.drawingsPdf;
  const {name, couponPercentage} = couponData;
  
  useEffect(() => {
    if (
      spaceSize > 0 &&
      ((unit === "metric" && spaceSize <= 30000) ||
        (unit === "imperial" && spaceSize <= 300000)) &&
      propertyType.length > 0 &&
      (interiorScope.length > 0 || exteriorScope.length > 0)
    ) {
      const totalPrice = getTotalPriceDigital(
        spaceSize,
        unit,
        propertyType,
        interiorScope,
        exteriorScope,
        siteSize
      );

      const getWorkingDays = getWorkingDaysRequired(
        spaceSize,
        propertyType,
        interiorScope,
        exteriorScope,
        siteSize,
        unit
      );
      
      let priceInEuro = totalPrice + 1;

      priceInEuro = drawingsPdf ? priceInEuro + 80 : priceInEuro;

      setTotalWorkingDays(getWorkingDays);
      setPrice(priceInEuro.toFixed(2));
      if (couponInfo.isCouponApplied) {
        priceChangesBasedOnCoupon(priceInEuro.toFixed(2));
      }
      if (
        (digitalModel?.userId === null || digitalModel?.userId === "") &&
        getCurrentUser() !== null
      ) {
        setDigitalModel((prevState) => ({
          ...prevState,
          userId: getCurrentUser()["id"],
        }));
      }
    } else {

      setPrice(0);
      setTotalWorkingDays([]);
    }
  }, [spaceSize, propertyType, interiorScope, unit, exteriorScope, siteSize, drawingsPdf]);

  // useEffect(() => {
  //   if(spaceSize <= 0) return;
  //   console.log(drawingsPdf, spaceSize)
  //   if(drawingsPdf){
  //     setPrice((Number(price) + 80.0).toFixed(2))
  //   }else if(!drawingsPdf){
  //     setPrice((Number(price) - 80.0).toFixed(2))
  //   }
  // }, [drawingsPdf]);

  const checkIfCouponCanBeUsed = async () => {
    const payload = {
      userId: getCurrentUser()["id"],
      couponName: coupon,
    };

    try {
      // Call API to check if the coupon can be used
      var response = await checkCouponUsability(payload);

      // If the response indicates that the coupon can be used
      if (response.success) {
        return true; // Successfully assigned the coupon
      }
      return false; // Coupon cannot be used
    } catch (error) {
      return false;
    }
  };

  const priceChangesBasedOnCoupon = (originalPrice) => {
    var discountPrice =
      originalPrice - originalPrice * (couponData.couponPercentage / 100);
    setCouponInfo((prevState) => ({
      ...prevState,
      isCouponApplied: true,
      originalPrice: originalPrice,
      discountPrice: discountPrice.toFixed(2),
    }));
  };

  const handleCouponApplication = async () => {
    if (!!coupon === false) {
      toast.error(t("common.couponRequired"));
      return;
    }
    try {
      setCouponLoading(true)
      var canUseCoupon = await checkIfCouponCanBeUsed();

      if (!canUseCoupon) return false;
      var couponDto = await getCouponByName(coupon);
  
      if(couponDto?.category !== "digital"){
        toast.error(t("common.couponIncorrectCategory"))
        return;
      }
     
      setCouponData(couponDto);
      var discountPrice =
        price - price * (couponDto.couponPercentage / 100);
      setCouponInfo((prevState) => ({
        ...prevState,
        isCouponApplied: true,
        originalPrice: price,
        discountPrice: discountPrice.toFixed(2),
      }));
    } catch (error) {
      
    }finally{
      setCouponLoading(false)
    }
   
  };

  const handleRemoveCoupon = () => {
    setCouponInfo({
      isCouponApplied: false,
      originalPrice: 0,
      discountPrice: 0,
    });
    setCouponData({});
    setCoupon("");
  };

  const handleDigitalOrderPost = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (getCurrentUser() === null) {
        navigate("/login");
        return;
      }

      const validateProject = validateDigitalProject(digitalModel);
      
      if (validateProject.length === 0) {
        let newPrice = price;

        if (couponInfo.isCouponApplied) {
          newPrice = couponInfo.discountPrice;
        }

        const projectExits = await getDigitalProjectByName(
          digitalModel.projectName
        );
        if (!projectExits?.success) return;
      
        const updatedDigitalModel = {
          ...digitalModel,
          couponName: couponInfo.isCouponApplied ? couponData.name : "",
          totalPrice: newPrice,
          userId: getCurrentUser()["id"],
          workingDays: Object.values(workingDays),
          couponId: couponInfo.isCouponApplied ? couponData.id : null,
        };
        setDigitalModel(updatedDigitalModel);
        const response = await postDigitalOrder(updatedDigitalModel);

        if (response && response.success) {
          navigate("/request-successful?type=order");
        }
        return response;
        // localStorage.setItem(
        //   "digitalModel",
        //   JSON.stringify(updatedDigitalModel)
        // );
        // const resp = await paymentCheckout({
        //   totalPrice: newPrice,
        //   serviceType: "digital",
        // });
        // window.location.href = resp.sessionUrl;
      } else {
        const formattedMessage = validateProject.join("\n");
        // Display the toast notification with formatted message
        toast.error(formattedMessage, { duration: 7000 });
      }
    } catch (error) {
    } finally {
  
      setLoading(false);
    }
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const projectId = query.get("id");
  // const success = query.get('success');

  // const digitalOrder = async () => {
  //   try {
  //     const model = localStorage.getItem("digitalModel");

  //     let parsedModel = null;
  //     if (model) {
  //       try {
  //         parsedModel = JSON.parse(model);
  //       } catch (error) {

  //         localStorage.removeItem("digitalModel");
  //       }
  //     }
  //     setDigitalModel(parsedModel);

  //     if (parsedModel.couponName !== "") {
  //       await userUseCoupon(parsedModel.couponName);
  //     }

  //     parsedModel.projectId = projectId;
  //     const response = await postDigitalOrder(parsedModel);
  //     console.log('digital response', response)

  //     if (response && response.success) {
  //       navigate("/request-successful");
  //     }
  //     return response;
  //   } catch (error) {

  //     throw error;
  //   } finally {
  //     localStorage.removeItem("digitalModel");
  //     setLoading(false);
  //     setDigitalModel(initialRealEstateDigital);
  //   }
  // };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setLoading(true);
      const responseOrder = digitalOrder();
      // Call the digitalOrder function and handle loading state via promise
      toast
        .promise(responseOrder, {
          loading: t("order.processing"),
          success: t("order.success"),
          error: t("order.error"),
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (query.get("canceled")) {
      localStorage.removeItem("digitalModel");
      //toast.error("Order canceled. Please try again.");
      setLoading(false);
    }
  }, []);

  return (
    <div className="price-container">
      <div className="card-container">
        <h3 className="card-title">{t("digitalPrice.totalAmount")}</h3>
        {couponInfo.isCouponApplied ? (
            <>
              <p className="price ca">
                Original price:
                <span className="coupon-applied">
                  {" "}
                  € {couponInfo.originalPrice}
                </span>
              </p>
              <p className="price">
                Discount price: € {couponInfo.discountPrice}
              </p>
            </>
          ) : (
            <p className="price">€ {price}</p>
          )}
        <>
          {workingDays.interior?.trim() && (
            <p className="days-txt">
              {t("digitalPrice.interior")}: {workingDays?.interior}{" "}
              {t("digitalPrice.workingDays")}
            </p>
          )}
          {workingDays.exterior?.trim() && (
            <p className="days-txt">
              {t("digitalPrice.exterior")}: {workingDays?.exterior}{" "}
              {t("digitalPrice.workingDays")}
            </p>
          )}
          {workingDays.site?.trim() && (
            <p className="days-txt">
              {t("digitalPrice.site")}: {workingDays?.site}{" "}
              {t("digitalPrice.workingDays")}
            </p>
          )}
        </>

        {/* <p className="days-txt">{t('digitalPrice.noWorkingDays')}</p> */}

        <Button
          variant="contained"
          color="inherit"
          sx={{
            backgroundColor: "#1a477f",
            color: "white",
            padding: 1,
            textTransform: "none",
            width: 150,
            "&:hover": {
              backgroundColor: "#122c51",
            },
          }}
          onClick={handleDigitalOrderPost}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={24} /> : null}
        >
          {loading
            ? t("digitalPrice.processing")
            : t("digitalPrice.convertNow")}
        </Button>
      </div>
      <div className="img-price-container">
        <img className="imgCalc" src={calcImage} alt="imgCalculate" />
      </div>
      {couponInfo?.isCouponApplied === false ? 
       <div className="card-container">
        <h3 className="card-title">{t("digitalPrice.couponQuestion")}</h3>
        <TextField
          label={t("digitalPrice.couponCode")}
          variant="outlined"
          size="small"
          onChange={(e) => setCoupon(e.target.value)}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontSize: "12px",
              padding: "6px",
            },
            width: "220px",
          }}
          InputProps={{
            fontSize: "12px",
            padding: "14px",
            endAdornment: (
              <Tooltip
                title={t("digitalPrice.couponTooltip")}
                arrow
                PopperProps={{
                  disablePortal: true,
                  style: { marginTop: "8px" },
                }}
              >
                <IconButton>
                  <img
                    style={{ width: "15px" }}
                    src={InfoIcon}
                    alt="info-icon"
                  />
                </IconButton>
              </Tooltip>
            ),
          }}
          InputLabelProps={{
            sx: {
              fontSize: "11px",
              fontWeight: "600",
              fontFamily: "Roboto, sans-serif",
              padding: "8px",
              marginLeft: "-3px",
              shrink: true,
            },
          }}
          style={{ marginBottom: 8, fontSize: "12px" }}
        />
        <Button
              variant="contained"
              color="inherit"
              disabled={couponLoading}
              sx={{
                backgroundColor: "#1a477f",
                color: "white",
                padding: 1,
                textTransform: "none",
                width: 150,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                "&:hover": {
                  backgroundColor: "#122c51",
                },
              }}
              onClick={handleCouponApplication}
              startIcon={
                couponLoading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  <DiscountIcon
                    size={24}
                    sx={{ color: "white", mb: -0.1, mr: -1 }}
                  />
                )
              }
            >
              {couponLoading ? t("priceScan.processing") : t("common.applyDiscount")}
            </Button>
      </div>
      :
      <div className="card-container-coupon">
      <div style={{ alignSelf: "center" }}>
        <CheckCircleIcon style={{ fontSize: "5rem", color: "#4caf50" }} />
      </div>

      <p>
      {t("common.couponApplied", {name, couponPercentage})}
      </p>
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <p style={{ color: "grey", fontSize: "14px" }}>
        {t("common.removeCoupon")}
        </p>
        <Tooltip title="Remove">
          <CancelIcon
          onClick={handleRemoveCoupon}
            style={{
              fontSize: "1.8rem",
              color: "red",
              marginBottom: "-0px",
              cursor: "pointer",
            }}
          />
        </Tooltip>
      </div>
    </div>
    }
     
    </div>
  );
};

export default PricingCardDigital;
