import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
import {
  FaFacebook,
  FaInstagram,
  FaEnvelope,
  FaLinkedin,
  FaWhatsapp,
  FaCopy,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

const ShareDialog = ({ open, onClose, url, title }) => {
  const { t } = useTranslation();
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);

  const copyProjectShareLink = () => {
    navigator.clipboard.writeText(url);
    toast.success(t("common.copySuccess"));
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("share.title")}</DialogTitle>
      <DialogContent>
        <div className="share-buttons">
          {/* Facebook */}
          <Tooltip title="Facebook">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook size={32} />
            </a>
          </Tooltip>

          {/* Email */}
          <Tooltip title="Email">
            <a
              href={`mailto:?subject=${encodedTitle}&body=${encodedUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaEnvelope color="#d44638" size={32} />
            </a>
          </Tooltip>

          {/* Instagram */}
          <Tooltip title="Instagram">
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram color="#C13584" size={32} />
            </a>
          </Tooltip>

          {/* LinkedIn */}
          <Tooltip title="LinkedIn">
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin color="#0a66c2" size={32} />
            </a>
          </Tooltip>

          {/* WhatsApp */}
          <Tooltip title="WhatsApp">
            <a
              href={`https://api.whatsapp.com/send?text=${encodedUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp color="green" size={32} />
            </a>
          </Tooltip>

          {/* Copy Link */}
          <Tooltip title="Copy Link">
            <a href="#" onClick={() => copyProjectShareLink()}>
              <FaCopy color="grey" size={32} />
            </a>
          </Tooltip>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("share.close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog;
