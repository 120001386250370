import './App.css';
import Layout from './Components/Layout';
import MyFallbackComponent from './Error-Handling/ErrorBoundary';
import { ErrorBoundary } from 'react-error-boundary';

function App() {

  return (
    <ErrorBoundary
      FallbackComponent={MyFallbackComponent} 
      onReset={() => {
    
      }}
      onError={(error, errorInfo) => {
     
       // console.error("Error caught by ErrorBoundary:", error, errorInfo);
      }}
    >
      <div style={{ minHeight: '100vh', overflowX: 'hidden' }}>
        <Layout />
      </div>
    </ErrorBoundary>
  );
}

export default App;
