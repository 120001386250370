import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  borderBottom: '1px solid #ddd',
  '&:before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
  
}));

const StyledAccordionSummaryContent = styled('div')(({ theme }) => ({
  '&.Mui-expanded': {
    margin: '12px 0',
  },
  

}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: '8px 24px',
 
}));

export default function FAQ() {
  const { t } = useTranslation();
  
  const faqData = [
    {
      category: t('faq.scanning.category'),
      questions: [
        {
          question: t('faq.scanning.questions.0.question'),
          answer: t('faq.scanning.questions.0.answer')
        },
        {
          question: t('faq.scanning.questions.1.question'),
          answer: t('faq.scanning.questions.1.answer')
        },
        {
          question: t('faq.scanning.questions.2.question'),
          answer: t('faq.scanning.questions.2.answer')
        },
        {
          question: t('faq.scanning.questions.3.question'),
          answer: t('faq.scanning.questions.3.answer')
        },
        {
          question: t('faq.scanning.questions.4.question'),
          answer: t('faq.scanning.questions.4.answer')
        },
        {
          question: t('faq.scanning.questions.5.question'),
          answer: t('faq.scanning.questions.5.answer')
        },
        {
          question: t('faq.scanning.questions.6.question'),
          answer: t('faq.scanning.questions.6.answer')
        },
        {
          question: t('faq.scanning.questions.7.question'),
          answer: t('faq.scanning.questions.7.answer')
        },
        {
          question: t('faq.scanning.questions.8.question'),
          answer: t('faq.scanning.questions.8.answer')
        },
        {
          question: t('faq.scanning.questions.9.question'),
          answer: t('faq.scanning.questions.9.answer')
        },
        {
          question: t('faq.scanning.questions.10.question'),
          answer: t('faq.scanning.questions.10.answer')
        },
        {
          question: t('faq.scanning.questions.11.question'),
          answer: t('faq.scanning.questions.11.answer')
        },

      ]
    },
    {
      category: t('faq.digitalization.category'),
      questions: [
        {
          question: t('faq.digitalization.questions.0.question'),
          answer: t('faq.digitalization.questions.0.answer')
        },
        {
          question: t('faq.digitalization.questions.1.question'),
          answer: t('faq.digitalization.questions.1.answer')
        },
        {
          question: t('faq.digitalization.questions.2.question'),
          answer: t('faq.digitalization.questions.2.answer')
        },
        {
          question: t('faq.digitalization.questions.3.question'),
          answer: t('faq.digitalization.questions.3.answer')
        },
        {
          question: t('faq.digitalization.questions.4.question'),
          answer: t('faq.digitalization.questions.4.answer')
        },
        {
          question: t('faq.digitalization.questions.5.question'),
          answer: t('faq.digitalization.questions.5.answer')
        },
        {
          question: t('faq.digitalization.questions.6.question'),
          answer: t('faq.digitalization.questions.6.answer')
        },
        {
          question: t('faq.digitalization.questions.7.question'),
          answer: t('faq.digitalization.questions.7.answer')
        },
        {
          question: t('faq.digitalization.questions.8.question'),
          answer: t('faq.digitalization.questions.8.answer')
        },
        {
          question: t('faq.digitalization.questions.9.question'),
          answer: t('faq.digitalization.questions.9.answer')
        },
        {
          question: t('faq.digitalization.questions.10.question'),
          answer: t('faq.digitalization.questions.10.answer')
        },
        {
          question: t('faq.digitalization.questions.11.question'),
          answer: t('faq.digitalization.questions.11.answer')
        },

      ]
    }
  ];

  return (
    <div className="faq-container">
      <Typography variant="h4" sx={{ textAlign: 'center', mb: '0px', mt:5 }} className="gradient-text">
        <span>{t('faq.title')}</span>
      </Typography>
      {faqData.map((section, index) => (
        <div key={index} className="faq-content">
          <Typography variant="h6" sx={{ ml: '16px', mt: '60px' }}>
            {section.category}:
          </Typography>
          {section.questions.map((item, qIndex) => (
            <StyledAccordion key={qIndex}>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${qIndex}-content`}
                id={`panel${qIndex}-header`}
                className={StyledAccordionSummaryContent}
              >
                <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: 500 }}>
                  {item.question}
                </Typography>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <Typography sx={{ color: '#4d4d4d', fontSize: '14px', fontWeight: 500 }}>
                  {item.answer}
                </Typography>
              </StyledAccordionDetails>
            </StyledAccordion>
          ))}
        </div>
      ))}
    </div>
  );
}
